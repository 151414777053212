<template>
  <div>
    <transition name="fade">
      <div
        v-if="loading"
        key="1"
        class="d-flex align-items-center justify-content-center"
        style="height: 80vh"
      >
        <b-spinner variant="primary" />
      </div>
      <div
        v-else
        key="2"
      >
        <div class="d-flex">
          <feather-icon
            icon="ArrowLeftIcon"
            size="24"
            class="align-middle text-primary mr-2 mb-2 back"
            @click="$router.go(-1)"
          />
          <h3>Detail Customer</h3>
        </div>
        <b-row>
          <b-col
            cols="12"
            xl="9"
            lg="8"
            md="7"
          >
            <b-card>
              <b-row>
                <b-col
                  cols="21"
                  xl="6"
                  class="d-flex justify-content-start"
                >
                  <b-avatar
                    :src="userData.fotoProfil"
                    :text="avatarText(userData.nama)"
                    variant="light-primary"
                    size="104px"
                    rounded
                  />
                  <div class="d-flex flex-column ml-1">
                    <div class="mb-1">
                      <h4 class="mb-0">
                        {{ userData.nama }}
                      </h4>
                      <div class="mt-50">
                        <Promised :promise="getSaldo(userData.no_hp)">
                          <template v-slot:pending>
                            <small>Tunggu...</small>
                          </template>
                          <template
                            v-slot="data"
                          >
                            <b-badge
                              :variant="data < 20000 ? 'light-danger' : 'light-primary'"
                            >
                              <small class="mr-25 text-secondary">Saldo</small>
                              {{ data }}
                            </b-badge>
                          </template>
                        </Promised>
                      </div>
                    </div>
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  xl="6"
                >
                  <table class="mt-2 mt-xl-0 w-100">
                    <tr>
                      <th>
                        <feather-icon
                          icon="PhoneIcon"
                          class="mr-75"
                        />
                        <span class="font-weight-bold">Nomor HP</span>
                      </th>
                      <td>
                        {{ userData.no_hp }}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <feather-icon
                          icon="UserIcon"
                          class="mr-75"
                        />
                        <span class="font-weight-bold">Email</span>
                      </th>
                      <td>
                        {{ userData.email }}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <feather-icon
                          icon="ClockIcon"
                          class="mr-75"
                        />
                        <span class="font-weight-bold">Waktu Daftar</span>
                      </th>
                      <td>
                        {{ userData.tanggal_daftar }}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <feather-icon
                          icon="CircleIcon"
                          class="mr-75"
                        />
                        <span class="font-weight-bold">Jenis Kelamin</span>
                      </th>
                      <td>
                        {{ userData.gender === 0 ? 'Laki Laki' : 'Perempuan' }}
                      </td>
                    </tr>
                  </table>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col
            cols="12"
            md="5"
            xl="3"
            lg="4"
          >
            <b-card>
              <b-button
                v-if="userData.block_layanan"
                variant="outline-success"
                block
                @click="aktifkanCustomer()"
              >
                Aktifkan
              </b-button>
              <b-button
                variant="success"
                block
              >
                Ubah
              </b-button>
              <b-button
                variant="danger"
                block
              >
                Hapus
              </b-button>
              <hr>
              <b-button
                variant="outline-warning"
                block
                @click="bottomSheetBlokirCustomerOpen()"
              >
                Blokir
              </b-button>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            lg="8"
            md="6"
          >
            <b-card title="Riwayat Pesanan">
              <b-table
                :items="getPesanan"
                striped
                hover
                responsive
                :fields="fieldsOrders"
              >
                <template #cell(nama_layanan)="data">
                  <router-link
                    :to="{ name: 'DetailPesanan', params: { id: data.item.id } }"
                  >
                    {{ data.item.layanan[0].nama_layanan }}
                  </router-link>
                </template>
                <template #cell(total)="data">
                  {{ data.value }}
                </template>
                <template #cell(status)="data">
                  <b-badge :variant="statusOrder(data.value).variant">
                    {{ statusOrder(data.value).display }}
                  </b-badge>
                </template>
              </b-table>
              <div
                v-if="loadMorebtnRiwayat"
                class="d-flex mt-2"
              >
                <b-button
                  class="btn mx-auto btn-success"
                  @click="loadMoreRiwayat()"
                >
                  <feather-icon icon="ChevronDownIcon" />
                  Lihat lebih
                </b-button>
              </div>
            </b-card>
          </b-col>
          <b-col
            cols="12"
            md="5"
            lg="4"
          >
            <b-card
              v-if="getPayment.length > 0"
              class="card-transaction"
              no-body
            >
              <b-card-header>
                <b-card-title>MecarePay</b-card-title>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="18"
                  class="cursor-pointer"
                />
              </b-card-header>

              <b-card-body>
                <div
                  v-for="transaction in getPayment"
                  :key="transaction.mode"
                  class="transaction-item"
                >
                  <b-media no-body>
                    <b-media-aside>
                      <b-avatar
                        rounded
                        size="42"
                        variant="light-primary"
                      >
                        <feather-icon
                          size="18"
                          icon="PocketIcon"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body>
                      <h6 class="transaction-title">
                        MecarePay
                      </h6>
                      <small>{{ transaction.label }}</small>
                    </b-media-body>
                  </b-media>
                  <div
                    class="font-weight-bolder"
                    :class="transaction.kategori == 'keluar' ? 'text-danger' : 'text-success'"
                  >
                    {{ transaction.jumlah }}
                  </div>
                </div>
              </b-card-body>

              <div
                v-if="loadMorebtnMecarepay"
                class="d-flex mb-2"
              >
                <b-button
                  size="sm"
                  class="btn mx-auto btn-success"
                  @click="loadMoreMecarepay()"
                >
                  <feather-icon icon="ChevronDownIcon" />
                  Lihat lebih
                </b-button>
              </div>
            </b-card>
          </b-col>
          <b-col
            cols="12"
            md="5"
            xl="3"
            lg="4"
          >
            <b-card title="History Nama">
              <div
                v-for="hisName in userData.histori_nama"
                :key="hisName"
              >
                <b-card
                  no-body
                  border-variant="primary"
                  class="d-flex"
                >
                  <div class="d-flex justify-content-center m-1">
                    <h6>
                      {{ hisName }}
                    </h6>
                  </div>
                </b-card>
              </div>
            </b-card>
          </b-col>

          <vue-bottom-sheet
            ref="tampilkanBlokirCustomer"
            :rounded="false"
            :click-to-close="false"
            variant="success"
            block
          >
            <div class="bottom-sheet">
              <div class="d-flex justify-content-between mb-1 align-content-center">
                <h4>Blokir Customer</h4>
                <feather-icon
                  icon="XIcon"
                  size="28"
                  class="text-primary"
                  @click="bottomSheetBlokirCustomerClose"
                />
              </div>
              <hr>
              <div class="d-flex flex-column pb-5">
                <h5>Pilih layanan yang akan diblokir</h5>
                <v-select
                  v-model="selectLayanan"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="label"
                  multiple
                  :options="layananOptions"
                  placeholder="Pilih layanan"
                  class="mt-1"
                />
                <b-button
                  variant="success"
                  class="mt-1"
                  @click="tambahBlockLayanan(currentIdBottomSheet)"
                >
                  Blokir Customer
                </b-button>
              </div>
            </div>
          </vue-bottom-sheet>
        </b-row>
      </div>
    </transition>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BAvatar,
  BBadge,
  BSpinner,
  BCardTitle,
  BCardBody,
  BCardHeader,
  BMediaBody,
  BMedia,
  BMediaAside,
  BTable,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import VueBottomSheet from '@webzlodimir/vue-bottom-sheet'
import 'vue-img-viewr/styles/index.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import firebase from 'firebase/app'
import vSelect from 'vue-select'
import { Promised } from 'vue-promised'
import regionJson from '@/db/region.json'
import { db } from '@/firebase'

/* eslint-disable arrow-body-style */
/* eslint-disable radix */
export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BBadge,
    BCardTitle,
    BCardBody,
    BSpinner,
    BCardHeader,
    BMediaBody,
    BMedia,
    BMediaAside,
    BTable,

    Promised,
    VueBottomSheet,
    vSelect,
  },
  props: ['id'],
  data() {
    return {
      perPageMecarepay: 5,
      perPageOptionsMecarepay: [5, 10, 15],
      perPageRiwayat: 5,
      perPageOptionsRiwayat: [5, 10, 15],
      loading: true,
      userData: '',
      getPesanan: [],
      getPayment: [],
      region: regionJson,
      swiperData: [],
      previewData: [],
      layananOptions: [
        { value: 'vl01', label: 'Akupuntur' },
        { value: 'vl02', label: 'Auto' },
        { value: 'vl03', label: 'Barber' },
        { value: 'vl04', label: 'Bekam' },
        { value: 'vl05', label: 'Beauty' },
        { value: 'vl06', label: 'Cleaning' },
        { value: 'vl07', label: 'Massage' },
        { value: 'vl08', label: 'Cuci AC' },
      ],
      selectLayanan: '',
      currentIdBottomSheet: '',
      fieldsOrders: [
        { key: 'nama_layanan', label: 'Nama Layanan', sortable: false },
        { key: 'total', label: 'Total', sortable: false },
        { key: 'status', label: 'Status', sortable: false },
      ],
    }
  },
  created() {
    this.getCustomer()
    this.getOrder()
    this.getMecarePay()
  },
  methods: {
    async getSaldo(noHp) {
      let saldo = 0
      const nomer = noHp.substring(1)
      const options = {
        url: 'https://us-central1-mecare-life.cloudfunctions.net/getSaldoMitra',
        method: 'POST',
        data: {
          no_hp: nomer,
        },
      }
      await this.$axios(options).then(res => {
        saldo = parseInt(res.data)
      })
      return saldo
    },
    getDate(unix) {
      const timestamp = unix
      const dateObject = new Date(timestamp)

      return dateObject.toLocaleString('id-ID', {
        weekday: 'long', day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric',
      })
    },
    getMecarePay() {
      const limit = this.perPageMecarepay <= 5 ? 5 : this.perPageMecarepay
      this.loadMorebtnMecarepay = true

      db.collection('customer').doc(this.id).collection('payment_log')
        .limit(limit)
        .orderBy('waktu', 'desc')
        .get()
        .then(snapshot => {
          snapshot.docs.forEach(docs => {
            const data = docs.data()
            data.id = docs.id
            this.getPayment.push(data)
          })
        })
    },
    getOrder() {
      const limit = this.perPageRiwayat <= 5 ? 5 : this.perPageRiwayat
      this.loadMorebtnRiwayat = true

      db.collection('orders').limit(limit).where('uid_customer', '==', this.id).orderBy('waktu', 'desc')
        .get()
        .then(snapshot => {
          snapshot.docs.forEach(docs => {
            const data = docs.data()
            data.id = docs.id
            this.getPesanan.push(data)
          })
        })
    },
    getCustomer() {
      db.collection('customer').doc(this.id).get().then(async snapshot => {
        this.userData = snapshot.data()

        this.loading = false
      })
    },
    statusOrder(status) {
      if (status === 0) return { variant: 'light-danger', display: 'Membuat proses' }
      if (status === 1) return { variant: 'light-primary', display: 'Mencari Mitra' }
      if (status === 2) return { variant: 'light-primary', display: 'Terjadwal' }
      if (status === 3) return { variant: 'light-primary', display: 'Mitra dalam perjalanan' }
      if (status === 4) return { variant: 'light-primary', display: 'Mitra sudah sampai' }
      if (status === 5) return { variant: 'light-primary', display: 'Sedang mengerjakan' }
      if (status === 6) return { variant: 'light-primary', display: 'Menunggu pembayaran' }
      if (status === 7) return { variant: 'light-primary', display: 'Selesai' }
      if (status === 8) return { variant: 'danger', display: 'Dibatalkan oleh customer' }
      if (status === 9) return { variant: 'danger', display: 'Dibatalkan oleh sistem' }
      return { variant: 'light-secondary', display: 'tidak diketahui' }
    },
    loadMoreRiwayat() {
      if (this.getPesanan.length === this.perPageRiwayat) {
        this.perPageRiwayat += 15
        this.getOrder()
      } else {
        this.loadMorebtnRiwayat = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Data sudah ditampilkan semua',
            icon: 'CheckIcon',
            variant: 'success',
            position: 'bottom',
          },
        })
      }
    },
    loadMoreMecarepay() {
      if (this.getMecarePay.length === this.perPageMecarepay) {
        this.perPageMecarepay += 15
        this.getMecarePay()
      } else {
        this.loadMorebtnMecarepay = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Data sudah ditampilkan semua',
            icon: 'CheckIcon',
            variant: 'success',
            position: 'bottom',
          },
        })
      }
    },
    tambahBlockLayanan() {
      const layananBlock = []

      this.selectLayanan.forEach(vertikal => {
        layananBlock.push(vertikal.value)
      })

      db.collection('customer').where('objectID', '==', this.id).get().then(() => {
        db.collection('customer').doc(this.id).set({
          block_layanan: layananBlock,
        }, { merge: true })
      })
        .then(() => {
          this.bottomSheetBlokirCustomerClose()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Blokir Customer Success',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
    },
    bottomSheetBlokirCustomerOpen() {
      this.currentIdBottomSheet = this.id
      this.$refs.tampilkanBlokirCustomer.open()
    },
    bottomSheetBlokirCustomerClose() {
      this.$refs.tampilkanBlokirCustomer.close()
    },
    aktifkanCustomer() {
      this.$swal({
        title: 'Aktifkan customer ini ?',
        text: 'Konfirmasi jika anda ingin mengaktifkan customer ini',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          db.collection('customer').doc(this.id).update({
            block_layanan: firebase.firestore.FieldValue.delete(),
          }).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Customer berhasil diaktifkan',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          })
        }
      })
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style>
  .badge-mitra{
    margin: 2px;
  }

  .dokumen-card img{
    object-fit: cover;
  }

  .dropdown-blokir:hover{
    background-color: #ff9f43;
  }

  .dropdown-item:hover, .dropdown-item:focus{
    color: #fff;
  }

  .hitsList {
    list-style-type: none !important;
    padding-left: 0;
  }

  .bottom-sheet {
    padding: 16px 16px 0px 16px
  }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
